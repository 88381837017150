/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdditionalItemDto {
  /** @format int32 */
  id?: number | null;
  categoryName?: string | null;
  isNumberOfRooms?: boolean;
  /** @format int32 */
  numberOfMinutes?: number | null;
  /** @format date-time */
  startDate?: string | null;
  itemName?: string | null;
  /** @format int32 */
  itemCode?: number;
  /** @format int32 */
  sorting?: number;
}

export interface AdditionalItemListDto {
  /** @format int32 */
  id?: number;
  categoryName?: string | null;
  isNumberOfRooms?: boolean;
  /** @format int32 */
  numberOfMinutes?: number | null;
  itemName?: string | null;
  /** @format int32 */
  itemCode?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format int32 */
  sorting?: number;
}

/** @format int32 */
export enum AdditionalRequestApprover {
  Value0 = 0,
  Value1 = 1,
}

export interface AdditionalRequestCategoryDto {
  categoryName?: string | null;
  /** @format double */
  value?: number;
  additionalRequestItems?: AdditionalRequestItemDto[] | null;
}

export interface AdditionalRequestItemDto {
  /** @format double */
  value?: number;
  /** @format double */
  quantity?: number;
  itemName?: string | null;
  /** @format int32 */
  itemId?: number;
  /** @format int32 */
  sorting?: number;
  isNumberOfRooms?: boolean;
  /** @format int32 */
  numberOfMinutes?: number | null;
  comment?: string | null;
}

/** @format int32 */
export enum AdditionalRequestStatus {
  Value1 = 1,
  Value10 = 10,
  Value15 = 15,
  Value20 = 20,
  Value25 = 25,
  Value30 = 30,
}

export interface AfasLogDto {
  /** @format int32 */
  id?: number;
  afasLogType?: AfasLogType;
  message?: string | null;
  requestJson?: string | null;
  /** @format date-time */
  created?: string;
  responseJson?: string | null;
}

export interface AfasLogDtoPagedResultDto {
  items?: AfasLogDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

/** @format int32 */
export enum AfasLogType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface ApplicationInfoDto {
  version?: string | null;
  /** @format date-time */
  releaseDate?: string;
  features?: Record<string, boolean | null>;
}

export interface AuthenticateModel {
  /**
   * @minLength 0
   * @maxLength 256
   */
  userNameOrEmailAddress: string;
  /**
   * @minLength 0
   * @maxLength 32
   */
  password: string;
  rememberClient?: boolean;
  tenancyName?: string | null;
}

export interface AuthenticateResultModel {
  accessToken?: string | null;
  encryptedAccessToken?: string | null;
  /** @format int32 */
  expireInSeconds?: number;
  /** @format int64 */
  userId?: number;
  refreshToken?: string | null;
  user?: UserModel;
}

export interface ChangePasswordDto {
  /** @minLength 1 */
  currentPassword: string;
  /** @minLength 1 */
  newPassword: string;
}

export interface ChangeUiThemeInput {
  /**
   * @minLength 0
   * @maxLength 32
   */
  theme: string;
}

export interface ChangeUserLanguageDto {
  /** @minLength 1 */
  languageName: string;
}

export interface CommentDto {
  value?: string | null;
  userName?: string | null;
  /** @format date-time */
  creationTime?: string;
}

export interface CopyItemConfigurationInput {
  /** @format int32 */
  copyFromProjectId?: number;
  /** @format int32 */
  copyToProjectId?: number;
}

export interface CreateOrUpdateAdditionalItemDto {
  /** @format int32 */
  projectId?: number;
  items?: AdditionalItemDto[] | null;
}

export interface CreateOrUpdateItemDto {
  /** @format int32 */
  projectId?: number;
  items?: ItemDto[] | null;
}

export interface CreateOrUpdateReleaseDto {
  /** @format int32 */
  id?: number | null;
  /**
   * @minLength 1
   * @maxLength 150
   */
  title: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  versionNumber: string;
  /** @format date-time */
  releaseDate: string;
  content?: string | null;
}

export interface CreateRoleDto {
  /**
   * @minLength 0
   * @maxLength 32
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  displayName: string;
  normalizedName?: string | null;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  description?: string | null;
  grantedPermissions?: string[] | null;
}

export interface CreateTenantDto {
  /**
   * @minLength 0
   * @maxLength 64
   * @pattern ^[a-zA-Z][a-zA-Z0-9_-]{1,}$
   */
  tenancyName: string;
  /**
   * @minLength 0
   * @maxLength 128
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 256
   */
  adminEmailAddress: string;
  /**
   * @minLength 0
   * @maxLength 32
   */
  password: string;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  connectionString?: string | null;
  isStayplease?: boolean;
  tenantSignOffFlow?: TenantSignOffFlow;
  isActive?: boolean;
}

export interface CreateUserDto {
  /**
   * @minLength 0
   * @maxLength 256
   */
  userName: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  surname: string;
  /**
   * @format email
   * @minLength 0
   * @maxLength 256
   */
  emailAddress: string;
  isActive?: boolean;
  roleNames?: string[] | null;
  /**
   * @minLength 0
   * @maxLength 32
   */
  password: string;
  projectIds?: number[] | null;
}

export interface DailyDto {
  /** @format date-time */
  date?: string;
  /** @format int32 */
  projectId?: number;
  sendForReview?: boolean;
  comment?: string | null;
  dailyItems?: DailyItemDto[] | null;
}

export interface DailyItemDto {
  /** @format double */
  value?: number;
  itemName?: string | null;
  /** @format int32 */
  itemId?: number;
  /** @format int32 */
  sorting?: number;
  isApproved?: boolean;
}

/** @format int32 */
export enum DailyStatus {
  Value1 = 1,
  Value5 = 5,
  Value10 = 10,
  Value14 = 14,
  Value15 = 15,
  Value20 = 20,
}

export interface DailyStatusMatrixDto {
  tenantName?: string | null;
  /** @format int32 */
  hfcId?: number;
  projectName?: string | null;
  dailyStatus?: string | null;
  additionalItemStatus?: string | null;
  isExportedToAfas?: boolean;
}

export interface DashboardCommentItem {
  value?: string | null;
  userName?: string | null;
  /** @format date-time */
  creationTime?: string;
}

export interface DropdownDto {
  value?: string | null;
  label?: string | null;
}

export interface DropdownDtoPagedResultDto {
  items?: DropdownDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

export interface ERPExportModel {
  /** @format int32 */
  hfcId?: number;
  /** @format int32 */
  supplierCode?: number | null;
  /** @format int32 */
  administrationCode?: number | null;
  projectName?: string | null;
  /** @format date-time */
  dailyDate?: string;
  /** @format int32 */
  year?: number;
  /** @format int32 */
  month?: number;
  dailyItemName?: string | null;
  /** @format int32 */
  dailyItemNumber?: number;
  /** @format double */
  dailyItemValue?: number;
  poNumber?: string | null;
  isApproved?: boolean;
  isClosed?: boolean;
}

export interface EndOfDayResult {
  checkOut?: StaypleaseItemDto;
  stayOver?: StaypleaseItemDto;
  mandatory?: StaypleaseItemDto;
  endOfDayTotal?: StaypleaseItemDto;
  movementRooms?: StaypleaseItemDto;
  checkOutChecks?: StaypleaseItemDto;
}

export interface FlatPermissionDto {
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
}

export interface GetAdditionalRequestDto {
  /** @format int32 */
  year?: number;
  /** @format int32 */
  week?: number;
  /** @format int32 */
  weekPart?: number;
  /** @format int32 */
  projectId?: number;
  comment?: string | null;
  hasSecondWeekPart?: boolean;
  status?: AdditionalRequestStatus;
  additionalRequestCategories?: AdditionalRequestCategoryDto[] | null;
}

export interface GetAdditionalRequestOverviewDto {
  isApproved?: boolean;
  isDeclined?: boolean;
  /** @format int32 */
  year?: number;
  /** @format int32 */
  week?: number;
  /** @format int32 */
  weekPart?: number;
  /** @format int32 */
  projectId?: number;
  needsPurchaseOrder?: boolean;
  purchaseOrder?: string | null;
  comment?: string | null;
  /** @format int32 */
  overviewId?: number | null;
  hasSecondWeekPart?: boolean;
  status?: AdditionalRequestStatus;
  additionalRequestCategories?: AdditionalRequestCategoryDto[] | null;
}

export interface GetCurrentLoginInformationsOutput {
  application?: ApplicationInfoDto;
  user?: UserLoginInfoDto;
  tenant?: TenantLoginInfoDto;
}

export interface GetDailyDto {
  /** @format date-time */
  date?: string;
  /** @format int32 */
  projectId?: number;
  sendForReview?: boolean;
  dailyItems?: DailyItemDto[] | null;
  projectName?: string | null;
  comment?: string | null;
  status?: DailyStatus;
}

export interface GetDeclinedDailyDto {
  projectName?: string | null;
  /** @format int32 */
  projectId?: number;
  /** @format date-time */
  startDate?: string;
  comment?: string | null;
}

export interface GetERPExportInput {
  /** @format int32 */
  projectId?: number;
  /** @format int32 */
  week?: number;
  /** @format int32 */
  weekPart?: number;
  /** @format int32 */
  year?: number;
}

export interface GetProjectForEditDto {
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  overviewType?: number;
  city?: string | null;
  name?: string | null;
  needsPurchaseOrder?: boolean;
  staypleaseDatabaseName?: string | null;
  /** @format int32 */
  hfcId?: number;
  hasRoomChecks?: boolean;
  /** @format int32 */
  numberOfRoomChecks?: number | null;
  /** @format int32 */
  administrationCode?: number | null;
  /** @format int32 */
  supplierCode?: number | null;
  linkedUsers?: LinkedUser[] | null;
  linkedApprovers?: ProjectApproverDto[] | null;
  roomChecks?: RoomChecksDto[] | null;
}

export interface GetRoleForEditOutput {
  role?: RoleEditDto;
  permissions?: FlatPermissionDto[] | null;
  grantedPermissionNames?: string[] | null;
}

export interface GetStaypleaseDailyDto {
  /** @format date-time */
  date?: string;
  /** @format int32 */
  projectId?: number;
  projectName?: string | null;
  isOverridden?: boolean;
  /** @format date-time */
  overridenOnDate?: string | null;
  status?: StaypleaseDailyStatus;
  startOfDay?: StartOfDayResult;
  endOfDay?: EndOfDayResult;
  originalEndOfDay?: EndOfDayResult;
  toMarks?: ToMarkResult[] | null;
  comments?: DashboardCommentItem[] | null;
  corrections?: StaypleaseDailyCorrectionDto[] | null;
}

export interface GetStaypleaseOverrideDailyDto {
  /** @format date-time */
  date?: string;
  comments?: DashboardCommentItem[] | null;
  /** @format int32 */
  projectId?: number;
  projectName?: string | null;
  isOverridden?: boolean;
  readyForOverride?: boolean;
  status?: StaypleaseDailyStatus;
  endOfDay?: EndOfDayResult;
  originalEndOfDay?: EndOfDayResult;
}

export interface GetTenantInformationOutput {
  /** @format int32 */
  id?: number;
  tenancyName?: string | null;
  name?: string | null;
  isStayplease?: boolean;
  canSwitchTenant?: boolean;
  hasNotSeenReleaseNotes?: boolean;
  flowType?: TenantSignOffFlow;
  hasProjectsWithAdditionalItems?: boolean;
}

export interface GetUserForEditDto {
  /** @format int32 */
  id?: number | null;
  userName?: string | null;
  name?: string | null;
  surName?: string | null;
  emailAddress?: string | null;
  isActive?: boolean;
  fullName?: string | null;
  /** @format date-time */
  lastLoginTime?: string | null;
  /** @format date-time */
  creationTime?: string;
  roleNames?: string[] | null;
  linkedProjects?: LinkedProject[] | null;
}

export interface HostWeeklyTenantItemStatisticDto {
  /** @format date-time */
  date?: string;
  statusType?: WeeklyDailyItemStatusType;
  description?: string | null;
  /** @format int32 */
  dailyId?: number | null;
}

export interface HostWeeklyTenantProjectDto {
  /** @format int32 */
  projectId?: number;
  projectName?: string | null;
  /** @format int32 */
  numberOfApproved?: number;
  /** @format int32 */
  numberOfOutstanding?: number;
  /** @format int32 */
  numberOfDeclined?: number;
  isExportedToAfas?: boolean;
  isAdditionalRequestComplete?: boolean;
  hasAdditionalItemConfiguration?: boolean;
  additionalRequestStatus?: AdditionalRequestStatus;
  items?: HostWeeklyTenantItemStatisticDto[] | null;
}

export interface HostWeeklyTenantStatisticDto {
  /** @format int32 */
  tenantId?: number;
  tenantName?: string | null;
  weekNumber?: string | null;
  hasSecondWeekPart?: boolean;
  /** @format int32 */
  numberOfApproved?: number;
  /** @format int32 */
  numberOfOutstanding?: number;
  /** @format int32 */
  numberOfDeclined?: number;
  projects?: HostWeeklyTenantProjectDto[] | null;
}

export interface ImpersonateInput {
  /** @format int32 */
  tenantId?: number | null;
  /** @format int64 */
  userId?: number;
}

export interface ImpersonateOutput {
  impersonationToken?: string | null;
  tenancyName?: string | null;
}

export interface ImpersonatedAuthenticateResultModel {
  accessToken?: string | null;
  refreshToken?: string | null;
  encryptedAccessToken?: string | null;
  /** @format int32 */
  expireInSeconds?: number;
  user?: UserModel;
}

export interface Int64EntityDto {
  /** @format int64 */
  id?: number;
}

export interface IsTenantAvailableInput {
  /**
   * @minLength 0
   * @maxLength 64
   */
  tenancyName: string;
}

export interface IsTenantAvailableOutput {
  state?: TenantAvailabilityState;
  /** @format int32 */
  tenantId?: number | null;
}

export interface ItemApproverDto {
  isChecked?: boolean;
  /** @format int64 */
  roleId?: number;
}

export interface ItemCodeDto {
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  itemCode?: number;
}

export interface ItemCodePoDto {
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  itemCode?: number;
}

export interface ItemDto {
  /** @format int32 */
  id?: number | null;
  itemName?: string | null;
  /** @format int32 */
  sorting?: number;
  doNotSendToAFAS?: boolean;
  itemCodes?: ItemCodeDto[] | null;
  itemCodesPo?: ItemCodePoDto[] | null;
  approverIds?: number[] | null;
  applicableDays?: number[] | null;
}

export interface ItemListDto {
  /** @format int32 */
  id?: number;
  itemName?: string | null;
  doNotSendToAFAS?: boolean;
  itemCodes?: ItemCodeDto[] | null;
  itemCodesPoDto?: ItemCodePoDto[] | null;
  linkedApprovers?: ItemApproverDto[] | null;
  /** @format int32 */
  sorting?: number;
  applicableDays?: number[] | null;
}

export interface ItemListDtoPagedResultDto {
  items?: ItemListDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

export interface LinkedProject {
  /** @format int32 */
  projectId?: number;
  projectName?: string | null;
}

export interface LinkedUser {
  /** @format int64 */
  userId?: number;
  userName?: string | null;
}

export interface OverviewDay {
  /** @format date-time */
  date?: string;
  status?: DailyStatus;
  items?: OverviewItemDto[] | null;
}

export interface OverviewItemDto {
  itemName?: string | null;
  /** @format double */
  value?: number;
}

/** @format int32 */
export enum OverviewType {
  Value0 = 0,
  Value1 = 1,
}

export interface PermissionDto {
  /** @format int64 */
  id?: number;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
}

export interface PermissionDtoListResultDto {
  items?: PermissionDto[] | null;
}

export interface ProcessAdditionalRequestDto {
  /** @format int32 */
  year?: number;
  /** @format int32 */
  week?: number;
  /** @format int32 */
  weekPart?: number;
  sendForReview?: boolean;
  /** @format int32 */
  projectId?: number;
  items?: ProcessAdditionalRequestItem[] | null;
}

export interface ProcessAdditionalRequestItem {
  /** @format int32 */
  itemId?: number;
  /** @format double */
  quantity?: number;
  comment?: string | null;
}

export interface ProcessAdditionalRequestOverviewInput {
  signature?: string | null;
  comment?: string | null;
  isApproved?: boolean;
  /** @format int32 */
  overviewId?: number;
  /** @format int32 */
  projectId?: number;
  approver?: AdditionalRequestApprover;
}

export interface ProcessStaypleaseDailyInput {
  /** @format date-time */
  date?: string;
  /** @format int32 */
  projectId?: number;
  comment?: string | null;
  isOverridden?: boolean;
  items?: ProcessStaypleaseDailyItemInput[] | null;
  corrections?: StaypleaseDailyCorrectionDto[] | null;
}

export interface ProcessStaypleaseDailyItemInput {
  /** @format int32 */
  staypleaseItemId?: number;
  /** @format int32 */
  value?: number;
}

export interface ProcessWeeklyOverviewDto {
  signature?: string | null;
  overviewType?: OverviewType;
  comment?: string | null;
  isApproved?: boolean;
  /** @format date-time */
  date?: string;
  /** @format int32 */
  projectId?: number;
}

export interface ProjectApproverDto {
  /** @format int64 */
  roleId?: number;
  roleName?: string | null;
}

export interface ProjectEditDto {
  /** @format int32 */
  id?: number | null;
  /** @format int32 */
  overviewType?: number;
  city?: string | null;
  name?: string | null;
  needsPurchaseOrder?: boolean;
  /** @format int32 */
  hfcId?: number;
  userIds?: number[] | null;
  approverIds?: number[] | null;
  staypleaseDatabaseName?: string | null;
  /** @format int32 */
  administrationCode?: number | null;
  /** @format int32 */
  supplierCode?: number | null;
  /** @format date-time */
  roomCheckStartDate?: string;
  hasRoomChecks?: boolean;
  /** @format int32 */
  numberOfRoomChecks?: number | null;
}

export interface ProjectListDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  city?: string | null;
  /** @format int32 */
  hfcId?: number;
  /** @format date-time */
  creationTime?: string;
  isStaypleaseConfigurationValid?: boolean;
  hasRoomChecks?: boolean;
  /** @format int32 */
  numberOfRoomChecks?: number | null;
}

export interface ProjectListDtoPagedResultDto {
  items?: ProjectListDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

export interface ProquroOrderDto {
  poNumber?: string | null;
  isApproved?: boolean;
  lines?: ProquroOrderLine[] | null;
}

export interface ProquroOrderLine {
  /** @format int32 */
  itemCode?: number;
  itemName?: string | null;
  glCode?: string | null;
  /** @format double */
  amount?: number;
}

export interface RefreshTokenResult {
  accessToken?: string | null;
  encryptedAccessToken?: string | null;
  /** @format int32 */
  expireInSeconds?: number;
}

export interface RegisterInput {
  /**
   * @minLength 0
   * @maxLength 64
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  surname: string;
  /**
   * @minLength 0
   * @maxLength 256
   */
  userName: string;
  /**
   * @format email
   * @minLength 0
   * @maxLength 256
   */
  emailAddress: string;
  /**
   * @minLength 0
   * @maxLength 32
   */
  password: string;
  captchaResponse?: string | null;
}

export interface RegisterOutput {
  canLogin?: boolean;
}

export interface ReleaseNoteEditDto {
  /** @format int32 */
  id?: number | null;
  title?: string | null;
  versionNumber?: string | null;
  /** @format date-time */
  releaseDate?: string;
  content?: string | null;
}

export interface ReleaseNoteListDto {
  /** @format int32 */
  id?: number;
  title?: string | null;
  versionNumber?: string | null;
  releaseDate?: string | null;
  content?: string | null;
}

export interface ReleaseNoteListDtoPagedResultDto {
  items?: ReleaseNoteListDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

export interface ResetPasswordDto {
  /** @minLength 1 */
  adminPassword: string;
  /** @format int64 */
  userId: number;
  /** @minLength 1 */
  newPassword: string;
}

export interface ResetPasswordInput {
  /** @format int64 */
  userId?: number;
  /** @format int32 */
  tenantId?: number | null;
  resetCode?: string | null;
  password?: string | null;
  c?: string | null;
}

export interface ResetPasswordOutput {
  canLogin?: boolean;
  userName?: string | null;
}

export interface RoleDto {
  /** @format int32 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 32
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  displayName: string;
  normalizedName?: string | null;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  description?: string | null;
  grantedPermissions?: string[] | null;
}

export interface RoleDtoListResultDto {
  items?: RoleDto[] | null;
}

export interface RoleDtoPagedResultDto {
  items?: RoleDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

export interface RoleEditDto {
  /** @format int32 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 32
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  displayName: string;
  /**
   * @minLength 0
   * @maxLength 5000
   */
  description?: string | null;
  isStatic?: boolean;
}

export interface RoleListDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  displayName?: string | null;
  isStatic?: boolean;
  isDefault?: boolean;
  /** @format date-time */
  creationTime?: string;
}

export interface RoleListDtoListResultDto {
  items?: RoleListDto[] | null;
}

export interface RoomChecksDto {
  /** @format date-time */
  startDate?: string | null;
  /** @format int32 */
  numberOfRoomChecks?: number;
}

export interface SendPasswordResetCodeInput {
  tenantName?: string | null;
  /** @minLength 1 */
  emailAddress: string;
}

export interface SignOffInput {
  /** @format int32 */
  projectId?: number;
  /** @format date-time */
  date?: string;
  isApproved?: boolean;
  comment?: string | null;
  signature?: string | null;
}

export interface StartOfDayResult {
  departure?: StaypleaseItemDto;
  stayover?: StaypleaseItemDto;
  normalStayoverService?: StaypleaseItemDto;
  mandatoryStayoverService?: StaypleaseItemDto;
  occupiedCleanInspected?: StaypleaseItemDto;
  startOfDayTotal?: StaypleaseItemDto;
}

export interface StaypleaseDailyCorrectionDto {
  name?: string | null;
  value?: string | null;
}

/** @format int32 */
export enum StaypleaseDailyStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface StaypleaseItemDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  value?: number;
}

export interface StaypleaseItemListDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  code?: number;
  name?: string | null;
  type?: StaypleaseItemType;
}

export interface StaypleaseItemListDtoPagedResultDto {
  items?: StaypleaseItemListDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

/** @format int32 */
export enum StaypleaseItemType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface StaypleaseOverrideRoomNumbersDto {
  checkoutMinRooms?: string | null;
  checkoutPlusRooms?: string | null;
  stayoverMinRooms?: string | null;
  stayoverPlusRooms?: string | null;
  mandatoryMinRooms?: string | null;
  mandatoryPlusRooms?: string | null;
}

export interface StaypleaseOverviewCategoryDto {
  name?: string | null;
  items?: StaypleaseOverviewItemDto[] | null;
}

export interface StaypleaseOverviewDayDto {
  /** @format date-time */
  date?: string;
  isOverridden?: boolean;
  categories?: StaypleaseOverviewCategoryDto[] | null;
}

export interface StaypleaseOverviewItemDto {
  name?: string | null;
  /** @format int32 */
  value?: number;
}

export interface StaypleaseWeeklyOverviewDto {
  isApproved?: boolean;
  isDeclined?: boolean;
  /** @format int32 */
  projectId?: number;
  projectName?: string | null;
  needsPurchaseOrder?: boolean;
  purchaseOrder?: string | null;
  comment?: string | null;
  /** @format int32 */
  year?: number;
  comments?: CommentDto[] | null;
  dailyStatus?: StaypleaseDailyStatus;
  /** @format int32 */
  week?: number;
  /** @format int32 */
  weekPart?: number;
  hasSecondWeekPart?: boolean;
  /** @format int32 */
  totalDaysInWeek?: number;
  isReadyForSignOff?: boolean;
  totals?: StaypleaseOverviewCategoryDto[] | null;
  days?: StaypleaseOverviewDayDto[] | null;
}

export interface SwitchToLinkedAccountInput {
  /** @format int32 */
  targetTenantId?: number | null;
  /** @format int64 */
  targetUserId?: number;
}

/** @format int32 */
export enum TenantAvailabilityState {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface TenantDto {
  /** @format int32 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 64
   * @pattern ^[a-zA-Z][a-zA-Z0-9_-]{1,}$
   */
  tenancyName: string;
  /**
   * @minLength 0
   * @maxLength 128
   */
  name: string;
  isActive?: boolean;
  isStayplease?: boolean;
  tenantSignOffFlow?: TenantSignOffFlow;
}

export interface TenantDtoPagedResultDto {
  items?: TenantDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

export interface TenantLoginInfoDto {
  /** @format int32 */
  id?: number;
  tenancyName?: string | null;
  name?: string | null;
}

/** @format int32 */
export enum TenantSignOffFlow {
  Value1 = 1,
  Value2 = 2,
}

export interface TenantSwitchDto {
  /** @format int32 */
  tenantId?: number | null;
  /** @format int64 */
  userId?: number;
  tenantName?: string | null;
}

export interface TenantUsersDto {
  /** @format int64 */
  userId?: number;
  name?: string | null;
}

export interface TenantWeeklyItemStatisticDto {
  /** @format date-time */
  date?: string;
  statusType?: WeeklyDailyItemStatusType;
  description?: string | null;
  /** @format int32 */
  dailyId?: number | null;
}

export interface TenantWeeklyProjectDto {
  /** @format int32 */
  projectId?: number;
  projectName?: string | null;
  /** @format int32 */
  numberOfApproved?: number;
  isAdditionalRequestComplete?: boolean;
  hasAdditionalItemConfiguration?: boolean;
  additionalRequestStatus?: AdditionalRequestStatus;
  /** @format int32 */
  numberOfOutstanding?: number;
  /** @format int32 */
  numberOfDeclined?: number;
  items?: TenantWeeklyItemStatisticDto[] | null;
}

export interface TenantWeeklyStatisticDto {
  weekNumber?: string | null;
  hasSecondWeekPart?: boolean;
  isStaypleaseTenant?: boolean;
  projects?: TenantWeeklyProjectDto[] | null;
}

export interface ToMarkChangesDto {
  /** @format int32 */
  changesCheckout?: number;
  /** @format int32 */
  changesStayover?: number;
  /** @format int32 */
  changesMandatory?: number;
  /** @format int32 */
  changesOCI?: number;
}

export interface ToMarkItemResultDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  value?: number;
  /** @format int32 */
  toMarkId?: number;
  toMarkDescription?: string | null;
  roomNumbers?: string | null;
  /** @format int32 */
  linenStatus?: number | null;
  /** @format int32 */
  optType?: number | null;
}

export interface ToMarkResult {
  toMarkCategoryName?: string | null;
  /** @format int32 */
  toMarkCategoryId?: number;
  /** @format int32 */
  total?: number;
  toMarkItems?: ToMarkItemResultDto[] | null;
}

export interface UpdateStaypleaseItemRequest {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  code: number;
}

export interface UserDto {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 256
   */
  userName: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  surname: string;
  /**
   * @format email
   * @minLength 0
   * @maxLength 256
   */
  emailAddress: string;
  isActive?: boolean;
  fullName?: string | null;
  /** @format date-time */
  lastLoginTime?: string | null;
  /** @format date-time */
  creationTime?: string;
  roleNames?: string[] | null;
  projectIds?: number[] | null;
}

export interface UserDtoPagedResultDto {
  items?: UserDto[] | null;
  /** @format int32 */
  totalCount?: number;
}

export interface UserLoginInfoDto {
  /** @format int64 */
  id?: number;
  name?: string | null;
  surname?: string | null;
  userName?: string | null;
  emailAddress?: string | null;
}

export interface UserModel {
  roles?: string[] | null;
  fullName?: string | null;
  email?: string | null;
  /** @format int64 */
  userId?: number;
}

export interface UserOutOfOfficeDto {
  userIds?: number[] | null;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  startDate?: string;
}

/** @format int32 */
export enum WeeklyDailyItemStatusType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface WeeklyOverviewDto {
  isApproved?: boolean;
  isDeclined?: boolean;
  /** @format int32 */
  projectId?: number;
  projectName?: string | null;
  needsPurchaseOrder?: boolean;
  purchaseOrder?: string | null;
  comment?: string | null;
  /** @format int32 */
  year?: number;
  comments?: CommentDto[] | null;
  /** @format int32 */
  week?: number;
  /** @format int32 */
  weekPart?: number;
  hasSecondWeekPart?: boolean;
  /** @format int32 */
  totalDaysInWeek?: number;
  totalItems?: OverviewItemDto[] | null;
  minDailyStatus?: DailyStatus;
  days?: OverviewDay[] | null;
}
