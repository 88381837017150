import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getStayPleaseDataForSignOff,
  processStayPleaseDataSignOff,
} from "../../services/stayPleaseService";
import { GetCitizenMSignOffData } from "../../types";
import * as moment from "moment";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Input,
  Row,
} from "reactstrap";
import AppButton from "../components/Common/AppButton";
import { Form } from "react-bootstrap";
import { AppSignaturepad } from "../components/forms/AppSignaturepad";
import AppTextAreaInput from "../components/forms/AppTextAreaInput";
import { Formik } from "formik";
import { Stayplease_daily_status_types } from "../../utility/constants";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import HeaderBanner from "../components/banners/HeaderBanner";
import { getProject } from "../../services/projectService";
import React from "react";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";
import DatePickerFilter from "../components/Common/DateFilterPickr";

const CmApproveData = () => {
  //TODO: write typescript file to validate the roles

  const [filterDate, setFilterDate] = useState(moment().toDate());
  const [overviewData, setOverviewData] = useState<GetCitizenMSignOffData>();
  const [fetchingData, setFetchingData] = useState(false);
  const [saving, setSaving] = useState(false);
  const [project, setProject] = useState();
  const [dataEntryComplete, setDataEntryComplete] = useState(false);

  const { role, id } = useParams();

  useEffect(() => {
    getOverviewData(parseInt(id!), filterDate);
    getProjectData(parseInt(id!));
  }, []);

  const getProjectData = async (id: number) => {
    await getProject(id).then((result) => {
      setProject(result.data.result);
    });
  };

  const getOverviewData = async (projectId: number, date: Date) => {
    setFetchingData(true);
    await getStayPleaseDataForSignOff(projectId, date.toISOString()).then(
      (result) => {
        setFetchingData(false);
        if (result) {
          setOverviewData(result.data.result);
        }
      }
    );
  };

  const handleDatePickerChange = (newDate) => {
    setFilterDate(newDate.date);
    getOverviewData(parseInt(id!), newDate.date);
  };

  const handleNextPrevDate = (isPrevious: boolean) => {
    if (isPrevious) {
      const newDate = moment(filterDate).clone().endOf("day").add(-1, "days");
      getOverviewData(parseInt(id!), newDate.toDate());
      setFilterDate(newDate.toDate());
    } else {
      const newDate = moment(filterDate).clone().endOf("day").add(1, "days");
      getOverviewData(parseInt(id!), newDate.toDate());
      setFilterDate(newDate.toDate());
    }
  };

  const handleSubmit = async (input: ProcessData) => {
    if (!input.isApproved && (!input.comment || input.comment.trim().length < 5)) {
      return Message(
        "Missing comment",
        "You must add a comment when declining the daily",
        "warningNoBtns"
      );
    }

    setSaving(true);

    await processStayPleaseDataSignOff({
      comment: input.comment,
      date: filterDate,
      isApproved: input.isApproved,
      projectId: parseInt(id!),
      signature: input.signature,
    }).then(() => {
      setSaving(false);

      const overviewDataCopy = { ...overviewData! };

      if (role === "MOD" && input.isApproved) {
        overviewDataCopy.status = Stayplease_daily_status_types.IsApprovedByMOD;
      } else {
        overviewDataCopy.status = Stayplease_daily_status_types.IsDeclined;
      }

      setOverviewData(overviewDataCopy);
      Notify.success("Data processed");
    });
  };

  interface ProcessData {
    isApproved: boolean;
    comment: string;
    signature: string;
  }

  const initialValues: ProcessData = {
    comment: overviewData?.comment || "",
    isApproved: false,
    signature: "",
  };

  return (
    <div>
      <PageTitle title={project?.name || ""} customStyles="mb-2">
        <DatePickerFilter
          handleChange={(date) => handleDatePickerChange({ date: date })}
          classNames="ms-2"
        />
      </PageTitle>

      <DateToggler
        title={`${moment(filterDate).format(
          "dddd | DD-MM-yyyy | "
        )} week ${moment(filterDate).isoWeek()}`}
        onNextDate={() => handleNextPrevDate(false)}
        onPreviousDate={() => handleNextPrevDate(true)}
      />
      

      {fetchingData && (
        <Row className="text-center">
          <h4>Loading...</h4>
        </Row>
      )}

      {!fetchingData && !overviewData && (
        <Row className="text-center">
          <h4>Administration has not yet been submitted...</h4>
        </Row>
      )}

      {overviewData && (
        <>
          {overviewData.status === Stayplease_daily_status_types.IsDeclined && (
            <HeaderBanner
              title="Daily declined"
              message="This daily has been sent back to the housekeeper because it is declined"
              type="danger"
            />
          )}

          {overviewData.status !== Stayplease_daily_status_types.IsDeclined &&
            overviewData.status !==
              Stayplease_daily_status_types.SendForReview && (
              <HeaderBanner title="Daily approved" type="info" />
            )}

          {overviewData.isOverridden && (
            <HeaderBanner
              title={`Please note: this daily has been overridden on ${moment(
                overviewData.overridenOnDate
              ).format("DD-MM-YYYY")}`}
              type="warning"
            />
          )}

          <Accordion className="mb-5" open="accordionItem1StartOfDay">
            <AccordionItem>
              <AccordionHeader targetId="accordionItem1StartOfDay">
                Start of day
              </AccordionHeader>
              <AccordionBody accordionId="accordionItem1StartOfDay">
                <Row>
                  <Col lg={8} sm={8}>
                    <h6>Category</h6>
                  </Col>
                  <Col lg={4} sm={4}>
                    <h6>Amount</h6>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Departure" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={overviewData?.startOfDay?.departure?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Stayover" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={overviewData?.startOfDay?.stayover?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Normal stayover service" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        overviewData?.startOfDay?.normalStayoverService?.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Mandatory stayover service" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        overviewData?.startOfDay?.mandatoryStayoverService
                          ?.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input
                      value="Occupied clean inspected - opt-out"
                      disabled
                    />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        overviewData?.startOfDay?.occupiedCleanInspected?.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={12} className="text-end">
                    <h6 className="d-inline">Start of day total: {"  "}</h6>
                    <h5 className="d-inline">
                      {overviewData?.startOfDay?.startOfDayTotal?.value}
                    </h5>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

          <Accordion className="mb-5" open="accordionItem1EndOfDay">
            <AccordionItem>
              <AccordionHeader targetId="accordionItem1EndOfDay">
                End of day
              </AccordionHeader>
              <AccordionBody accordionId="accordionItem1EndOfDay">
                <Row>
                  <Col lg={8} sm={8}>
                    <h6>Category</h6>
                  </Col>
                  <Col lg={4} sm={4}>
                    <h6>Amount</h6>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Checkout" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={overviewData?.endOfDay?.checkOut?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4" key="eod3">
                  <Col lg={8} sm={8}>
                    <Input value="Stayover" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={overviewData?.endOfDay?.stayOver?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Mandatory clean" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={overviewData?.endOfDay?.mandatory?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4" key="eod3">
                  <Col lg={8} sm={8}>
                    <Input value="Movement Rooms" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={overviewData?.endOfDay?.movementRooms?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={12} className="text-end">
                    <h6 className="d-inline">End of day total: {"  "}</h6>
                    <h5 className="d-inline">
                      {overviewData?.endOfDay.endOfDayTotal.value}
                    </h5>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

          {overviewData?.isOverridden && (
            <Accordion className="mb-5" open="accordionItem1EndOfDay">
              <AccordionItem>
                <AccordionHeader targetId="accordionItem1EndOfDay">
                  End of day before correction
                </AccordionHeader>
                <AccordionBody accordionId="accordionItem1EndOfDay">
                  <Row>
                    <Col lg={8} sm={8}>
                      <h6>Category</h6>
                    </Col>
                    <Col lg={4} sm={4}>
                      <h6>Amount</h6>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={8} sm={8}>
                      <Input value="Checkout" disabled />
                    </Col>
                    <Col lg={4} sm={4}>
                      <Input
                        value={overviewData?.originalEndOfDay?.checkOut?.value}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4" key="eod3">
                    <Col lg={8} sm={8}>
                      <Input value="Stayover" disabled />
                    </Col>
                    <Col lg={4} sm={4}>
                      <Input
                        value={overviewData?.originalEndOfDay?.stayOver.value}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={8} sm={8}>
                      <Input value="Mandatory clean" disabled />
                    </Col>
                    <Col lg={4} sm={4}>
                      <Input
                        value={overviewData?.originalEndOfDay?.mandatory.value}
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} className="text-end">
                      <h6 className="d-inline">End of day total: {"  "}</h6>
                      <h5 className="d-inline">
                        {overviewData?.originalEndOfDay?.endOfDayTotal.value}
                      </h5>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          )}

          {overviewData?.toMarks.map((toMark) => {
            const accordionId = `accordionItem[${toMark.toMarkCategoryId}]-toMark`;
            return (
              <Accordion
                className="mb-5"
                open={accordionId}
                key={`${accordionId}-tomark`}
              >
                <AccordionItem>
                  <AccordionHeader targetId={accordionId}>
                    {toMark.toMarkCategoryName}
                  </AccordionHeader>
                  <AccordionBody accordionId={accordionId}>
                    <Row>
                      <Col lg={4} sm={4}>
                        <h6>Category</h6>
                      </Col>
                      <Col lg={6} sm={6}>
                        <h6>Room numbers</h6>
                      </Col>
                      <Col lg={2} sm={2}>
                        <h6>Amount</h6>
                      </Col>
                    </Row>
                    {toMark.toMarkItems.map((item) => {
                      return (
                        <Row
                          className="mb-4"
                          key={`toMarkItem${item.toMarkId}-${toMark.toMarkCategoryId}`}
                        >
                          <Col lg={4} sm={4}>
                            <Input value={item.toMarkDescription} disabled />
                          </Col>
                          <Col lg={6} sm={6}>
                            <Input value={item.roomNumbers || ""} disabled />
                          </Col>
                          <Col lg={2} sm={2}>
                            <Input value={item.value} disabled />
                          </Col>
                        </Row>
                      );
                    })}
                    <hr></hr>
                    <Row>
                      <Col lg={12} className="text-end">
                        <h6 className="d-inline">
                          {toMark.toMarkCategoryName} total: {"  "}
                        </h6>
                        <h5 className="d-inline">{toMark.total}</h5>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            );
          })}

          {overviewData && (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, setFieldValue, values }) => (
                <>
                  {overviewData.comments && (
                    <>
                      <h5>Comments</h5>
                      {overviewData.comments?.map((comment) => (
                        <MessageTimeLineItem
                          userName={comment.userName}
                          value={comment.value}
                          creationTime={new Date(comment.creationTime)}
                        />
                      ))}
                    </>
                  )}
                  {overviewData.status ===
                    Stayplease_daily_status_types.SendForReview && (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <h4>Info</h4>
                        <Col sm={12}>
                          <div className="mb-3">
                            <AppTextAreaInput
                              name="comment"
                              label="Comment"
                              rows="6"
                              disabled={
                                overviewData.status !==
                                Stayplease_daily_status_types.SendForReview
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="mb-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value="1"
                            onClick={(e) => {
                              setDataEntryComplete(e.target.checked);
                            }}
                            id="dataEntryCorrect"
                          />

                          <label
                            for="dataEntryCorrect"
                            className="form-check-label ms-2"
                          >
                            To my knowledge, this form has been filled in
                            correctly
                          </label>
                        </Col>
                      </Row>

                      <AppSignaturepad
                        setFieldValue={setFieldValue}
                        name="signature"
                        label="Signed by (official title)"
                      />

                      <Row className="text-end pt-5">
                        <Col>
                          <AppButton
                            variant="cancel"
                            disabled={
                              saving || !dataEntryComplete || !values.signature
                            }
                            className="me-3"
                            onClick={(e) => {
                              setFieldValue("isApproved", false);
                              handleSubmit(e);
                            }}
                            title="Decline daily"
                          ></AppButton>
                          <AppButton
                            variant="submit"
                            disabled={
                              saving || !dataEntryComplete || !values.signature
                            }
                            onClick={(e) => {
                              setFieldValue("isApproved", true);
                              handleSubmit(e);
                            }}
                            title="Approve daily"
                          ></AppButton>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </>
              )}
            </Formik>
          )}
        </>
      )}
    </div>
  );
};

export default CmApproveData;
