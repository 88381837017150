import { useEffect, useState } from "react";
import {
  getStayPleaseDaily,
  getStaypleaseDailyExport,
  processStaypleaseDaily,
} from "../../services/stayPleaseService";
import {
  GetProjectOutput,
  GetStaypleaseDailyOutput,
  ProcessStayPleasDailyInputItem,
} from "../../types";
import * as moment from "moment";
import PageTitle from "../components/Common/PageTitle";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Input,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { getProject } from "../../services/projectService";
import AppButton from "../components/Common/AppButton";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import { Stayplease_daily_status_types } from "../../utility/constants";
import DateToggler from "../components/Common/DateToggler";
import HeaderBanner from "../components/banners/HeaderBanner";
import React from "react";
import { saveAs } from "file-saver";
import DatePickerFilter from "../components/Common/DateFilterPickr";
import AppFormLabel from "../components/forms/AppFormLabel";
import { isHouseKeeperUser } from "../../utility/Utils";

interface StayPleaseDailyProps {
  id: number;
}

const StayPleaseDaily = ({ id }: StayPleaseDailyProps) => {
  const [stayPleaseDailyData, setStayPleaseDailyData] =
    useState<GetStaypleaseDailyOutput["result"]>();
  const [saving, setSaving] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [comment, setComment] = useState("");

  const [project, setProject] = useState<GetProjectOutput>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProject(id);
  }, [id]);

  async function fetchProject(projectId: number) {
    const result = await getProject(projectId);

    if (result) {
      const projectRecord: GetProjectOutput = result.data.result;
      setProject(projectRecord);

      if (projectRecord.staypleaseDatabaseName)
        fetchStaypleaseDaily(id, moment().toDate(), false);
    }
  }

  async function fetchStaypleaseDaily(
    projectId: number,
    date: Date,
    refreshStaypleaseData: boolean
  ) {
    setLoading(true);
    const result = await getStayPleaseDaily(
      projectId,
      date.toISOString(),
      refreshStaypleaseData
    );

    if (result) {
      setStayPleaseDailyData(result.data.result);
    }

    setLoading(false);
  }

  async function fetchStaypleaseDailyExport(projectId: number, date: Date) {
    setDownloadingFile(true);

    getStaypleaseDailyExport(projectId, date.toISOString()).then((result) => {
      setDownloadingFile(false);

      saveAs(
        new Blob([result.data]),
        `Daily-details-${project!.name}-${date.toISOString()}.xlsx`
      );
    });
  }

  const handleNextPrevDate = (isPrevious: boolean) => {
    if (isPrevious) {
      const newDate = moment(stayPleaseDailyData?.date)
        .clone()
        .endOf("day")
        .add(-1, "days");
      fetchStaypleaseDaily(id, newDate.toDate(), false);
    } else {
      const newDate = moment(stayPleaseDailyData?.date)
        .clone()
        .endOf("day")
        .add(1, "days");
      fetchStaypleaseDaily(id, newDate.toDate(), false);
    }
  };

  const handleSubmitForReview = () => {
    setSaving(true);
    Message(
      "Are you sure?",
      `Do you want to submit the daily data for review?`,
      "warning"
    )
      .then(async (result) => {
        if (result.isConfirmed) {
          let toMarkConvertedToPostData =
            new Array<ProcessStayPleasDailyInputItem>();

          stayPleaseDailyData?.toMarks.forEach((toMark) => {
            const toMarkConverted = toMark.toMarkItems.map((toMarkItem) => {
              return {
                value: toMarkItem.value,
                roomNumbers: toMarkItem.roomNumbers,
                staypleaseItemId: toMarkItem.id,
              } as ProcessStayPleasDailyInputItem;
            });

            toMarkConvertedToPostData.push(...toMarkConverted);
          });

          await processStaypleaseDaily({
            date: moment(stayPleaseDailyData!.date).toDate(),
            projectId: stayPleaseDailyData!.projectId,
            comment: comment,
            items: [
              {
                staypleaseItemId:
                  stayPleaseDailyData!.startOfDay?.departure?.id,
                value: stayPleaseDailyData!.startOfDay?.departure?.value,
              },
              {
                staypleaseItemId:
                  stayPleaseDailyData!.startOfDay.mandatoryStayoverService.id,
                value:
                  stayPleaseDailyData!.startOfDay.mandatoryStayoverService
                    .value,
              },
              {
                staypleaseItemId:
                  stayPleaseDailyData!.startOfDay.normalStayoverService.id,
                value:
                  stayPleaseDailyData!.startOfDay.normalStayoverService.value,
              },
              {
                staypleaseItemId:
                  stayPleaseDailyData!.startOfDay.occupiedCleanInspected.id,
                value:
                  stayPleaseDailyData!.startOfDay.occupiedCleanInspected.value,
              },
              {
                staypleaseItemId: stayPleaseDailyData!.startOfDay.stayover.id,
                value: stayPleaseDailyData!.startOfDay.stayover.value,
              },
              {
                staypleaseItemId: stayPleaseDailyData!.endOfDay.checkOut.id,
                value: stayPleaseDailyData!.endOfDay.checkOut.value,
              },
              {
                staypleaseItemId: stayPleaseDailyData!.endOfDay.mandatory.id,
                value: stayPleaseDailyData!.endOfDay.mandatory.value,
              },
              {
                staypleaseItemId: stayPleaseDailyData!.endOfDay.stayOver.id,
                value: stayPleaseDailyData!.endOfDay.stayOver.value,
              },
              {
                staypleaseItemId:
                  stayPleaseDailyData!.endOfDay.movementRooms.id,
                value: stayPleaseDailyData!.endOfDay.movementRooms.value,
              },
              {
                staypleaseItemId:
                  stayPleaseDailyData!.endOfDay.checkOutChecks.id,
                value: stayPleaseDailyData!.endOfDay.checkOutChecks.value,
              },
              ...toMarkConvertedToPostData,
            ],
          })
            .then(async (res) => {
              if (res.status === 200) {
                setSaving(false);
                Notify.success(`Data has been submitted for review!`);

                const stayPleaseData = { ...stayPleaseDailyData! };

                stayPleaseData.status =
                  Stayplease_daily_status_types.SendForReview;

                setStayPleaseDailyData(stayPleaseData);
              }
            })
            .catch((err) => {
              setSaving(false);

              if (err.response) {
                Message(
                  err.response.data.error.message,
                  err.response.data.error.details,
                  "error"
                );
              }
            });
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      {project && !project.staypleaseDatabaseName && (
        <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm">
          <div className="lh-1">
            <h1 className="h6 mb-0 text-white lh-1">
              This project hasn't been properly configured. Contact your
              administrator.
            </h1>
            <small>
              You cannot see the data because the project is missing one or more
              configuration items.
            </small>
          </div>
        </div>
      )}

      {stayPleaseDailyData &&
        stayPleaseDailyData.status != undefined &&
        stayPleaseDailyData.status !==
        Stayplease_daily_status_types.IsDeclined && (
          <HeaderBanner
            type="info"
            title="Daily sheet has been submitted for approval"
            message="  You can no longer edit this daily sheet because it has been submitted for approval."
          />
        )}

      {stayPleaseDailyData?.status ===
        Stayplease_daily_status_types.IsDeclined && (
          <HeaderBanner
            title="Daily declined"
            message={`This daily is declined, The following comment was added: ${stayPleaseDailyData.comments[0].value}`}
            type="danger"
          />
        )}

      {stayPleaseDailyData && project && (
        <>
          <PageTitle
            title={stayPleaseDailyData?.projectName}
            customStyles="mb-2"
          >
            <DatePickerFilter
              handleChange={(date) =>
                fetchStaypleaseDaily(id, moment(date).toDate(), false)
              }
              classNames="ms-2"
            />

            <button
              type="button"
              className="btn btn-xs btn-secondary ms-2"
              onClick={() =>
                fetchStaypleaseDaily(
                  id,
                  moment(stayPleaseDailyData.date).toDate(),
                  true
                )
              }
            >
              <FontAwesomeIcon icon={faRedo} />
            </button>
          </PageTitle>
        </>
      )}

      {stayPleaseDailyData?.date && stayPleaseDailyData.date && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <DateToggler
                title={`${moment(stayPleaseDailyData!.date).format(
                  "dddd | DD-MM-yyyy | "
                )} week ${moment(stayPleaseDailyData!.date).isoWeek()}`}
                onNextDate={() => handleNextPrevDate(false)}
                onPreviousDate={() => handleNextPrevDate(true)}
              />
            </div>

            <div>
              <AppButton
                variant="export"
                onClick={() => {
                  fetchStaypleaseDailyExport(
                    project!.id!,
                    moment(stayPleaseDailyData?.date).toDate()
                  );
                }}
                loading={downloadingFile}
                title="Download daily details"
              />
            </div>
          </div>
        </>
      )}

      {loading ? (
        <Row className="text-center">
          <h4>Loading...</h4>
        </Row>
      ) : (
        <>
          <Accordion className="mb-5" open="accordionItem1StartOfDay">
            <AccordionItem>
              <AccordionHeader targetId="accordionItem1StartOfDay">
                Start of day
              </AccordionHeader>
              <AccordionBody accordionId="accordionItem1StartOfDay">
                <Row>
                  <Col lg={8} sm={8}>
                    <h6>Category</h6>
                  </Col>
                  <Col lg={4} sm={4}>
                    <h6>Amount</h6>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Departure" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.startOfDay?.departure?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Stayover" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.startOfDay?.stayover?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Normal stayover service" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        stayPleaseDailyData?.startOfDay?.normalStayoverService
                          ?.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Mandatory stayover service" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        stayPleaseDailyData?.startOfDay
                          ?.mandatoryStayoverService?.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input
                      value="Occupied clean inspected - opt-out"
                      disabled
                    />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        stayPleaseDailyData?.startOfDay?.occupiedCleanInspected
                          ?.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={12} className="text-end">
                    <h6 className="d-inline">Start of day total: {"  "}</h6>
                    <h5 className="d-inline">
                      {stayPleaseDailyData?.startOfDay?.startOfDayTotal?.value}
                    </h5>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

          {stayPleaseDailyData?.isOverridden && (
            <Accordion className="mb-5" open="accordionItem1EndOfDay">
              <AccordionItem>
                <AccordionHeader targetId="accordionItem1EndOfDay">
                  End of day before correction
                </AccordionHeader>
                <AccordionBody accordionId="accordionItem1EndOfDay">
                  <Row>
                    <Col lg={8} sm={8}>
                      <h6>Category</h6>
                    </Col>
                    <Col lg={4} sm={4}>
                      <h6>Amount</h6>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={8} sm={8}>
                      <Input value="Checkout" disabled />
                    </Col>
                    <Col lg={4} sm={4}>
                      <Input
                        value={
                          stayPleaseDailyData?.originalEndOfDay?.checkOut?.value
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4" key="eod3">
                    <Col lg={8} sm={8}>
                      <Input value="Stayover" disabled />
                    </Col>
                    <Col lg={4} sm={4}>
                      <Input
                        value={
                          stayPleaseDailyData?.originalEndOfDay.stayOver.value
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={8} sm={8}>
                      <Input value="Mandatory clean" disabled />
                    </Col>
                    <Col lg={4} sm={4}>
                      <Input
                        value={
                          stayPleaseDailyData?.originalEndOfDay.mandatory.value
                        }
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} className="text-end">
                      <h6 className="d-inline">End of day total: {"  "}</h6>
                      <h5 className="d-inline">
                        {
                          stayPleaseDailyData?.originalEndOfDay.endOfDayTotal
                            .value
                        }
                      </h5>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          )}

          <Accordion className="mb-5" open="accordionItem1EndOfDay">
            <AccordionItem>
              <AccordionHeader targetId="accordionItem1EndOfDay">
                End of day
              </AccordionHeader>
              <AccordionBody accordionId="accordionItem1EndOfDay">
                <Row>
                  <Col lg={8} sm={8}>
                    <h6>Category</h6>
                  </Col>
                  <Col lg={4} sm={4}>
                    <h6>Amount</h6>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Checkout" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay?.checkOut?.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4" key="eod3">
                  <Col lg={8} sm={8}>
                    <Input value="Stayover" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.stayOver.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Mandatory clean" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.mandatory.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Movement rooms" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.movementRooms.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Check out checks" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.checkOutChecks.value}
                      disabled
                    />
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={12} className="text-end">
                    <h6 className="d-inline">End of day total: {"  "}</h6>
                    <h5 className="d-inline">
                      {stayPleaseDailyData?.endOfDay.endOfDayTotal.value}
                    </h5>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

          {stayPleaseDailyData?.toMarks.map((toMark) => {
            const accordionId = `accordionItem[${toMark.toMarkCategoryId}]-toMark`;
            return (
              <Accordion
                className="mb-5"
                open={accordionId}
                key={`${accordionId}-tomark`}
              >
                <AccordionItem>
                  <AccordionHeader targetId={accordionId}>
                    {toMark.toMarkCategoryName}
                  </AccordionHeader>
                  <AccordionBody accordionId={accordionId}>
                    <Row>
                      <Col lg={4} sm={4}>
                        <h6>Category</h6>
                      </Col>
                      <Col lg={6} sm={6}>
                        <h6>Room numbers</h6>
                      </Col>
                      <Col lg={2} sm={2}>
                        <h6>Amount</h6>
                      </Col>
                    </Row>
                    {toMark.toMarkItems.map((item) => {
                      return (
                        <Row
                          className="mb-4"
                          key={`toMarkItem${item.id}-${toMark.toMarkCategoryId}`}
                        >
                          <Col lg={4} sm={4}>
                            <Input value={item.toMarkDescription} disabled />
                          </Col>
                          <Col lg={6} sm={6}>
                            <Input value={item.roomNumbers} disabled />
                          </Col>
                          <Col lg={2} sm={2}>
                            <Input value={item.value} disabled />
                          </Col>
                        </Row>
                      );
                    })}
                    <hr></hr>
                    <Row>
                      <Col lg={12} className="text-end">
                        <h6 className="d-inline">
                          {toMark.toMarkCategoryName} total: {"  "}
                        </h6>
                        <h5 className="d-inline">{toMark.total}</h5>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            );
          })}


          {stayPleaseDailyData?.corrections && (
            <Accordion
              className="mb-5"
              open={'correction'}
              key={`correction-tomark`}
            >
              <AccordionItem>
                <AccordionHeader targetId='correction'>
                  Corrections selected rooms
                </AccordionHeader>
                <AccordionBody accordionId='correction'>
                  <Row>
                    <Col lg={6} sm={6}>
                      <h6>Category</h6>
                    </Col>
                    <Col lg={6} sm={6}>
                      <h6>Room numbers</h6>
                    </Col>

                  </Row>
                  {stayPleaseDailyData?.corrections && stayPleaseDailyData?.corrections.map((item) => {
                    return (
                      <Row
                        className="mb-4"
                        key={`correctionitems${item.name}`}
                      >
                        <Col lg={6} sm={6}>
                          <Input value={item.name} disabled />
                        </Col>
                        <Col lg={6} sm={6}>
                          <Input value={item.value} disabled />
                        </Col>

                      </Row>
                    );
                  })}
                  <hr></hr>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          )}



          {stayPleaseDailyData &&
            project &&
            (stayPleaseDailyData.status === null ||
              stayPleaseDailyData.status ===
              Stayplease_daily_status_types.IsDeclined) && isHouseKeeperUser() 
              && (
              <>
                <Row>
                  <h4>Info</h4>
                  <Col sm={12}>
                    <AppFormLabel label="Comment" />
                    <Input
                      type="textarea"
                      onChange={(event) => setComment(event.target.value)}
                    />
                  </Col>
                </Row>

                  <Row className="text-end pt-5">
                    <Col>
                      <AppButton

                        loading={saving}
                        disabled={saving}
                        type="submit"
                        variant="submit"
                        title="Send for review"
                        buttonSize="lg"
                        onClick={() => {
                          handleSubmitForReview();
                        }}
                      />
                    </Col>
                  </Row>
              </>
            )}
        </>
      )}
    </>
  );
};

export default StayPleaseDaily;
