import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Form,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { getDaily, processDaily } from "../../services/dailyService";
import { GetDailyDto } from "../../types/apiTypes";
import { Daily_status_types } from "../../utility/constants";
import Notify from "../../utility/notifications/Notify";
import AppButton from "../components/Common/AppButton";
import DatePickerFilter from "../components/Common/DateFilterPickr";
import PageTitle from "../components/Common/PageTitle";
import AppTextAreaInput from "../components/forms/AppTextAreaInput";
import AppTextInput from "../components/forms/AppTextInput";
import DeclinedDailyComponent from "./DeclinedDailyComponent";
import HeaderBanner from "../components/banners/HeaderBanner";

interface RegularDailyProps {
  id: number;
}

const validationSchema = Yup.object().shape({
  dailyItems: Yup.array().of(
    Yup.object().shape({
      itemId: Yup.string().required(),
      value: Yup.number().required().min(0).label("Amount"),
    })
  ),
});

const RegularDaily = ({ id }: RegularDailyProps) => {
  const [index, setIndex] = useState("accordionItem1");
  const [dataEntryComplete, setDataEntryComplete] = useState(false);
  const [declinedComponentKey, setDeclinedComponentKey] = useState(0);

  const [dailyReportData, setDailyReportData] = useState<GetDailyDto>();

  const navigateToSpecificDate = ({ startDate }) => {
    const newDate = moment(startDate);

    getDailyData(dailyReportData?.projectId, newDate);
  };

  const handleNextPrevDate = (isPrevious) => {
    if (isPrevious) {
      const newDate = moment(dailyReportData?.date)
        .clone()
        .endOf("day")
        .add(-1, "days");
      getDailyData(dailyReportData?.projectId, newDate);
    } else {
      const newDate = moment(dailyReportData?.date)
        .clone()
        .endOf("day")
        .add(1, "days");
      getDailyData(dailyReportData?.projectId, newDate);
    }
  };

  const handleDatePickerChange = (newDate) => {
    getDailyData(dailyReportData?.projectId, newDate.date);
  };

  function toggle(newIndex) {
    if (newIndex === index) {
      setIndex("accordionItem0");
    } else {
      setIndex(newIndex);
    }
  }

  const isEditingDisabled = (status, isApproved) => {
    var dailyStatus = [
      Daily_status_types.SendForReview,
      Daily_status_types.IsReadyForApprovalByAreaManager,
      Daily_status_types.ApprovedByAreaManager,
    ].includes(status);

    if (dailyStatus) {
      return true;
    }
    if (isApproved) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (data, { setSubmitting }) => {
    await processDaily(data).then(() => {
      if (data.status === Daily_status_types.SendForReview) {
        const newDailyData = { ...data };
        newDailyData.status = Daily_status_types.SendForReview;

        setDailyReportData(newDailyData);
        Notify.success(`Daily data has been submitted in for approval`);
      } else {
        Notify.success(`Daily data has been saved`);
      }

      setDataEntryComplete(false);
      setSubmitting(false);

      getDailyData(id, moment(dailyReportData?.date));
      setDeclinedComponentKey((prev) => prev + 1);
    });
  };

  useEffect(
    () => {
      getDailyData(id, moment());
    },
    [id],
    [dailyReportData]
  );

  async function getDailyData(projectId, date) {
    const result = await getDaily(projectId, date.toISOString());

    if (result) {
      setDailyReportData(result.data.result);
    }
  }

  return (
    <>
      {dailyReportData ? (
        <>
          <div className="flex flex-row d-inline">
            <PageTitle title={dailyReportData.projectName} customStyles="mb-2">
              <DatePickerFilter
                handleChange={(date) => handleDatePickerChange({ date: date })}
                classNames="ms-2"
              />
            </PageTitle>
          </div>

          <DeclinedDailyComponent
            key={declinedComponentKey}
            projectId={id}
            buttonText="Open"
            handleClick={navigateToSpecificDate}
          />

          {dailyReportData &&
            dailyReportData?.status >= 10 &&
            dailyReportData.status !==
              Daily_status_types.DeclinedByAreaManager &&
            dailyReportData.status !==
              Daily_status_types.ApprovedByAreaManager && (
              <HeaderBanner
                type="info"
                title="Daily sheet has been submitted for approval"
                message="  You can no longer edit this daily sheet because it has been submitted for approval."
              />
            )}

          {dailyReportData &&
            dailyReportData.status ===
              Daily_status_types.ApprovedByAreaManager && (
              <HeaderBanner type="info" title="The daily has been approved" />
            )}

          <div>
            <h4 className="mb-5 text-center">
              <FontAwesomeIcon
                icon={faAngleLeft}
                onClick={() => handleNextPrevDate(true)}
              />
              <span className="mx-3">
                {moment(dailyReportData.date).format("dddd | DD-MM-yyyy | ")}
                week {moment(dailyReportData.date).isoWeek()}
              </span>
              <FontAwesomeIcon
                icon={faAngleRight}
                onClick={() => handleNextPrevDate(false)}
              />
            </h4>
          </div>

          {dailyReportData &&
            (dailyReportData.status ===
              Daily_status_types.DeclinedByAreaManager ||
              dailyReportData.status ===
                Daily_status_types.IsDeclinedByMOD) && (
              <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm">
                <div className="lh-1">
                  <h1 className="h6 mb-0 text-white lh-1">
                    The daily sheet has been denied
                  </h1>
                  <small>
                    The following comment was added: {dailyReportData.comment}
                  </small>
                </div>
              </div>
            )}

          <Formik
            enableReinitialize={true}
            initialValues={{
              dailyItems: dailyReportData.dailyItems,
              projectId: dailyReportData.projectId,
              date: dailyReportData.date,
              comment: null,
              sendForReview: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Accordion open={index} toggle={toggle}>
                    <AccordionItem>
                      <AccordionHeader targetId="accordionItem1">
                        {dailyReportData.projectName}
                      </AccordionHeader>

                      <AccordionBody accordionId="accordionItem1">
                        <Row>
                          <Col lg={8} sm={8}>
                            <h6>Category</h6>
                          </Col>
                          <Col lg={4} sm={4}>
                            <h6>Amount</h6>
                          </Col>
                        </Row>

                        {values.dailyItems &&
                          values.dailyItems.map((item, i) => {
                            return (
                              <Row className="mb-4" key={i}>
                                <Col lg={8} sm={8}>
                                  <AppTextInput
                                    type="text"
                                    name={`dailyItems[${i}].itemName`}
                                    disabled
                                  />
                                </Col>
                                <Col lg={4} sm={4}>
                                  <AppTextInput
                                    disabled={isEditingDisabled(
                                      dailyReportData.status,
                                      item.isApproved
                                    )}
                                    type="number"
                                    name={`dailyItems[${i}].value`}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>

                  {!isEditingDisabled(dailyReportData.status, false) && (
                    <>
                      <Row className="mt-3">
                        <h4>Info</h4>
                        <Col sm={12}>
                          <div className="mb-3">
                            <AppTextAreaInput
                              name="comment"
                              label="Comment"
                              rows="6"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value="1"
                            onClick={(e) => {
                              setDataEntryComplete(e.target.checked);
                            }}
                            id="dataEntryCorrect"
                          />

                          <label
                            htmlFor="dataEntryCorrect"
                            className="form-check-label ms-2"
                          >
                            To my knowledge, this form has been filled in
                            correctly
                          </label>
                        </Col>
                      </Row>

                      <Row className="text-end pt-5">
                        <Col>
                          <AppButton
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            className="me-3"
                            variant="save"
                            type="submit"
                            title="Save"
                            buttonSize="lg"
                            onClick={(e) => {
                              handleSubmit(e);
                            }}
                          />

                          <AppButton
                            loading={isSubmitting}
                            disabled={!dataEntryComplete || isSubmitting}
                            type="submit"
                            variant="submit"
                            title="Send for review"
                            buttonSize="lg"
                            onClick={(e) => {
                              setFieldValue("sendForReview", true);
                              handleSubmit(e);
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default RegularDaily;
