const routeNames = {
  users: "/users",
  user_create: "/users/create",
  user_edit: "/users/edit/",
  profile: "/profile",
  login: "/login",
  logout: "/logout",
  to_impersonator: "/ToImpersonator",
  forgot_password: "/forgotpassword",
  account_reset_password: "/account/reset-password",
  account_activate: "/account/activate",
  hostDashboard: "/hostDashboard",
  matrixHostDashboard: "/matrix-hostDashboard",
  afas_logs: "/afas-logs",
  release_notes: "/releaseNotes",
  release_notes_create: "/releaseNotes/create",
  release_notes_edit: "/releaseNotes/create",
  release_notes_details: "/releaseNotes/details",
  tenants: "/tenants",
  tenant_create: "/tenants/create",
  tenant_edit: "/tenants/edit",
  additional_hours_overview: "/additionalHoursOverview/",
  daily: "/project/daily",
  additional_hours: "/additionalhours",
  projects: "/projects",
  project_create: "/projects/create",
  project_edit: "/projects/edit",
  spitemconfiguration: "/spitemconfiguration",
  data_approval: "/dataApproval",
  area_manager_dashboard: "/areaManagerDashboard",
  cm_data_approval: "/cmDataApproval",
  cm_data_approval_weekly: "/cmApproveDataWeekly",
  sp_corrections: "/corrections",
  faq: "/faq",
  switchUser: "/switchuser",
  signoff: "/signoff",
};

export default routeNames;
