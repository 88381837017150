import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Card, CardBody, CardHeader, Row } from "reactstrap";
import { GetLogs } from "../../services/afasLogService";
import AppModal from "../components/Common/AppModal";
import PageTitle from "../components/Common/PageTitle";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { AFASLogItem } from "../../types";

const AfasLogs = () => {
  const [logs, setLogs] = useState<AFASLogItem[]>();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [currentLogItem, setCurrentLogItem] = useState<AFASLogItem>();

  const openModel = (row) => {
    setCurrentLogItem(row);
    setShowModal(true);
  };

  async function fetchItems(skipcount, size = perPage) {
    setLoading(true);

    const response = await GetLogs(size, skipcount);

    if (response) {
      setLogs(response.data.result.items);
      setTotalRows(response.data.result.totalCount);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchItems(0);
  }, []);

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchItems(page * perPage, newPerPage);
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    fetchItems((page - 1) * perPage);
    setCurrentPage(page);
  };

  return (
    <div>
      <>
        <PageTitle title="AFAS logs" />
        {loading ? (
          <Row className="text-center">
            <h4>Loading...</h4>
          </Row>
        ) : (
          <Row>
            <DataTable
              pagination
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              paginationPerPage={perPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationServer
              responsive
              columns={[
                {
                  name: "id",
                  selector: (row) => row.id,
                  sortable: false,
                },
                {
                  name: "message",
                  selector: (row) => row.message,
                  sortable: false,
                },
                {
                  name: "created",
                  selector: (row) =>
                    moment(row.created).format("dddd | DD-MM-yyyy | hh:mm:ss"),
                  sortable: false,
                },
                {
                  name: "Actions",
                  cell: (row) => (
                    <Button
                      onClick={() => openModel(row)}
                      size="m"
                      color="primary"
                    >
                      <FontAwesomeIcon icon={faInfo} size="xs" />
                    </Button>
                  ),
                },
              ]}
              data={logs!}
            />
          </Row>
        )}

        <AppModal
          show={showModal}
          title="AFAS log"
          handleHide={() => {
            setShowModal(!showModal);
          }}
        >
          <>
            {currentLogItem && (
              <div className="d-flex mb-3">
                <Card className="w-100">
                  <CardHeader>
                    Message was: <strong>{currentLogItem.message}</strong> on{" "}
                    <strong>
                      {moment(currentLogItem.created).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                    </strong>
                  </CardHeader>
                  <CardBody>
                    <strong>The following request was send to AFAS:</strong>

                    <JSONPretty
                      id="json-pretty"
                      data={currentLogItem.requestJson}
                    ></JSONPretty>
                    <strong>We recieved the following response:</strong>
                    <JSONPretty
                      id="json-pretty"
                      data={currentLogItem.responseJson}
                    ></JSONPretty>
                  </CardBody>
                </Card>
              </div>
            )}
          </>
        </AppModal>
      </>
    </div>
  );
};

export default AfasLogs;
